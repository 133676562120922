import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import PrivateRoute from 'components/privateroute';
import Login from 'containers/login';
import Home from 'containers/home';
import VideoEditor from 'containers/video-editor'

import './App.css';

function App(props) {
    return (
        <Router>
            <Switch>
                <PrivateRoute logged={props.logged} exact path="/">
                    <Home />
                </PrivateRoute>
                <PrivateRoute logged={props.logged} exact path="/video-editor">
                    <VideoEditor />
                </PrivateRoute>
                <Route path="/login">
                    <Login />
                </Route>
            </Switch>
        </Router>
    )
}

const mapStateToProps = state => {
    return {
        logged: state.auth.logged,
    }
}

export default connect(mapStateToProps)(App);
