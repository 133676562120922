import React from 'react';
import classNames from 'classnames';

import styles from './button.module.css';


function Button(props) {
    const {type, ...rest} = props;
    const buttonType = props.type ? props.type : 'primary';

    return (
        <button className={classNames(styles.btn, styles[`btn-${buttonType}`])} {...rest} >{props.children}</button>
    )
}

export default Button;