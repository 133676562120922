import React from 'react';
import videojs from 'video.js';
import utf8 from 'utf8';

export default class VideoPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            skip: undefined,
        }
        this.globalKeyDownHandler = this.globalKeyDownHandler.bind(this);
    }

    globalKeyDownHandler(evt) {
        if (this.state.ready && evt.ctrlKey && evt.code === "Space") {
            if (this.player.paused()) {
                this.player.play();
            } else {
                this.player.pause();
            }
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.globalKeyDownHandler)
        this.player = videojs(this.videoNode, this.props, () =>{
            // console.log("onPlayerReady", this);
            this.setState({
                ready: true,
            })
        });
        this.player.on('timeupdate', (event) => {
            if (this.props.onProgress) {
                this.props.onProgress(this.player.currentTime());
            }
        })
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentTime !== prevProps.currentTime) {
            if (this.player) {
                this.player.currentTime(this.props.currentTime);
            }
        }

        if (this.props.subtitle !== prevProps.subtitle) {
            const tracks = this.player.textTracks();
            // console.log("### tracks", tracks)
            for (let i = tracks.length; i > 0; i--) {
                let track = tracks[i - 1];

                this.player.removeRemoteTextTrack(track);
            }
            if (this.props.subtitle) {
                const dataUrl = 'data:text/plain;base64,' + btoa(utf8.encode(this.props.subtitle));
                // console.log(dataUrl);
                this.player.addRemoteTextTrack({src: dataUrl, mode: 'showing', label: 'legenda', default: true}, false);
                // track.addEventListener('load', () => {
                //     track.mode = 'showing'
                // })
            }
        }
    }

    render() {
        return (
            <div>
                <div data-vjs-player>
                    <video ref={node => this.videoNode = node} className="video-js"></video>
                </div>
            </div>
        )
    }
}