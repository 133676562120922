import React, { useState } from 'react';
import Button from 'components/button';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { doLogin } from "actions/auth";

import styles from './login.module.css';

const LoginPage = ({ logged, busy, onLoginClick }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    if (logged) {
        return <Redirect to="/" />
    }

    const clearForm = () => {
        setUsername('');
        setPassword('');
    }

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <input className={styles.formItem} type="text" placeholder="login" value={username} onChange={e => setUsername(e.target.value)} />
                <input className={styles.formItem} type="password" placeholder="senha" value={password} onChange={e => setPassword(e.target.value)} />
                <div style={{ display: 'inline-flex', marginTop: 20, justifyContent: 'space-evenly' }}>
                    <Button type="secondary" style={{ margin: 2 }} onClick={clearForm}>Limpar</Button>
                    <Button
                        disabled={busy}
                        style={{ margin: 2 }}
                        onClick={() => onLoginClick(username, password)}>{busy ? 'Logando' : 'Login'}</Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        logged: state.auth.logged,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoginClick: (username, password) => {
            dispatch(doLogin(username, password));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
