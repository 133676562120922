import React from 'react';
import { connect } from 'react-redux';
import VideoPlayer from '../../components/video';
import WordMap from '../wordmap';
import { savePreview, savePending } from 'actions/video';


import * as subtitle from '../../utils/subtitle';

import 'video.js/dist/video-js.css';
import './index.css';

class VideoEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeskip: undefined,
            playTime: 0,
            mergeMode: false,
            highlight: true,
            text: undefined,
            showSubtitle: false,
            downloadUrl: undefined,
        }
        this.words = props.lastPreview;
        this.skip = this.skip.bind(this);
    };

    skip(timestamp) {
        console.log("skip", timestamp);
        this.setState({
            timeskip: timestamp,
        })
    }

    setPlaytime = (time) => {
        this.setState({
            playTime: time,
        })
    }

    mergeWords = (index1, index2) => {
        console.log("### mergeWords");
        if (index2 !== index1 + 1) {
            console.log("### mergeWords invalid indexes", index1, index2);
        } else {
            let x = [...this.props.lastPreview];
            let newItem = {
                id: x[index1].id,
                start_time: x[index1].start_time,
                end_time: x[index2].end_time,
                text: x[index1].text + ' ' + x[index2].text
            }

            x[index1] = newItem;
            x.splice(index2, 1);

            this.words = x;
            this.setState({
                mergeMode: false,
            })
            this.updateSubtitle();
        }
    }

    editWord = (index, text) => {
        let x = [...this.words];
        x[index] = { ...x[index], text: text }
        this.words = x;
        // this.forceUpdate();
        this.updateSubtitle();
    }

    updateSubtitle = () => {
        this.props.savePreview(this.words);
        this.props.savePending(true);
        const text = subtitle.createSubtitle(this.words);
        this.setState({
            text,
        })
    }

    downloadSubtitle = () => {
        const srt = subtitle.createSrt(this.words);
        if (this.state.downloadUrl) {
            window.URL.revokeObjectURL(this.state.downloadUrl);
        }

        const data = new File([srt], "legenda.srt", { type: 'octate/stream' });
        const downloadUrl = window.URL.createObjectURL(data);

        this.setState({
            downloadUrl
        })

        window.open(downloadUrl, '_blank');
    }

    updateWords = (newWords) => {
        this.words = newWords;
        this.updateSubtitle()
    }

    render() {
        return (
            <div style={{ display: 'flex', height: '100vh', minWidth: '50vw' }}>
                <WordMap data={this.words}
                    callback={this.skip}
                    highlight={this.state.highlight}
                    currentTime={this.state.playTime}
                    onMergeWords={this.mergeWords}
                    mergeMode={this.state.mergeMode}
                    onEditWord={this.editWord}
                    onUpdateWords={this.updateWords} />

                <div style={{ display: 'flex', flexDirection: 'column', width: '50vw' }} >
                    <VideoPlayer
                        autoplay={false}
                        controls={true}
                        sources={[{
                            src: this.props.videoSelected.url,
                            type: 'video/mp4',
                        }]}
                        width={640}
                        currentTime={this.state.timeskip}
                        onProgress={this.setPlaytime}
                        subtitle={this.state.showSubtitle ? this.state.text : undefined} />
                    <div>
                        <button onClick={() => this.setState({ mergeMode: !this.state.mergeMode })}>{this.state.mergeMode ? "Cancelar" : "Unir blocos"}</button>
                    </div>
                    <div>
                        <button
                            onClick={this.downloadSubtitle}
                        >
                            Baixar legenda
                        </button>
                    </div>
                    <div>
                        <input type="checkbox"
                            value="subtitle"
                            name="subtitle"
                            checked={this.state.showSubtitle}
                            onChange={evt => {
                                this.setState({ showSubtitle: evt.target.checked ? true : false });
                                if (evt.target.checked) {
                                    this.updateSubtitle();
                                }
                            }} />
                        <label htmlFor="subtitle">Mostrar legenda</label>
                    </div>
                    <div><input type="checkbox" value="Highlight" name="highlight" checked={this.state.highlight} onChange={evt => this.setState({ highlight: evt.target.checked ? true : false })} /><label htmlFor="highlight">Highlight</label></div>
                    <div>
                        <p>Ctrl+espaco - Play/pause</p>
                        <p>Click na palavra: ir no vídeo para onde a palavra ocorre</p>
                        <p>Shift+click na palavra: editar palavra</p>
                        <p>Para unir duas palavras clique em "Unir bloco" depois clique nas palavras </p>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    videoSelected: state.video.videoSelected,
    lastPreview: state.video.lastPreview
});

export default connect(mapStateToProps, { savePreview, savePending })(VideoEditor);
