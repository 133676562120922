export const DO_LOGIN = '@auth/do_login';
export const LOGIN_SUCCESS = '@auth/login_success';
export const LOGIN_FAIL = '@auth/login_fail';

export const doLogin = (username, password) => ({
    type: DO_LOGIN,
    payload: {
        username,
        password,
    }
})

export const loginSuccess = (payload) => ({
    type: LOGIN_SUCCESS,
    payload: {...payload}
})

export const loginFail = (payload) => ({
    type: LOGIN_FAIL,
    payload: {...payload}
})
