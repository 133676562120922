import * as Actions from 'actions';
import * as webapi from 'api';
import { all, call, put, takeEvery, throttle, select } from "redux-saga/effects";

function* loginSaga(action) {
    try {
        yield call(webapi.login, action.payload.username, action.payload.password);
        yield put(Actions.Auth.loginSuccess());
    } catch (error) {
        console.log("#### error", error);
        yield put(Actions.Auth.loginFail());
        alert('Login ou senha inválidos');
    }
}

function* listVideos({ payload }) {
    try {
        yield put(Actions.Video.changeLoading(true))
        const { data } = yield call(webapi.listVideos, payload.page, payload.pageSize);
        yield put(Actions.Video.changeVideos(data.data));
    } catch (error) {
        console.log("#### error", error);
    } finally {
        yield put(Actions.Video.changeLoading(false))
    }
}

function* fetchVideo({ payload }) {
    try {
        yield put(Actions.Video.changeLoading(true))
        const { data: videoSelected } = yield call(webapi.fetchVideo, payload.id);
        const { data: lastReview } = yield call(webapi.getReviewVideo, payload.id);
        const newReview = lastReview ? lastReview.text : videoSelected.transcription;
        yield put(Actions.Video.changeLastPreview(newReview));
        yield put(Actions.Video.changeVideoSelected(videoSelected));
    } catch (error) {
        console.log("#### error", error);
    } finally {
        yield put(Actions.Video.changeLoading(false))
    }
};

function* savePreview({ payload }) {
    try {
        const { videoSelected } = yield select(state => state.video);
        console.log(videoSelected);
        const res = yield call(webapi.saveReviewVideo, videoSelected.id, payload);
        yield put(Actions.Video.savePending(false));
        console.log(res);
    } catch (error) {
        console.log("#### error", error);
    }
}

function* watchLogin() {
    yield takeEvery(Actions.Auth.DO_LOGIN, loginSaga);
}


export default function* rootSaga() {
    yield all([
        watchLogin(),
        takeEvery(Actions.Video.FETCH_VIDEOS, listVideos),
        takeEvery(Actions.Video.FETCH_VIDEO, fetchVideo),
        throttle(15000, Actions.Video.SAVE_PREVIEW, savePreview),
    ])
}