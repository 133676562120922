import * as Actions from 'actions/video';

const INITIAL_STATE = {
    busy: false,
    videos: [],
    videoSelected: {},
    lastPreview: [],
    savePending: false,
}

const video = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {

        case Actions.CHANGE_VIDEOS:
            return { ...state, videos: payload };

        case Actions.CHANGE_LOADING:
            return { ...state, busy: payload };

        case Actions.CHANGE_VIDEO:
            return { ...state, videoSelected: payload };

        case Actions.CHANGE_LAST_PREVIEW:
            return { ...state, lastPreview: payload };

        case Actions.SAVE_PENDING:
            return { ...state, savePending: payload }

        default:
            return state;
    }
}

export default video;
