export function formatTimestamp(timestamp) {
    let millis = timestamp - parseInt(timestamp);
    timestamp = parseInt(timestamp);

    let hours = parseInt(timestamp / 3600);
    timestamp -= hours * 3600;

    let minutes = parseInt(timestamp / 60);
    let seconds = timestamp - (minutes * 60);

    millis = parseInt(millis * 1000).toFixed(0).padStart(3, '0')
    hours = hours.toFixed(0).padStart(2, '0')
    minutes = minutes.toFixed(0).padStart(2, '0')
    seconds = seconds.toFixed(0).padStart(2, '0')

    const result = `${hours}:${minutes}:${seconds}.${millis}`
    return result;
}

export function createSrt(words) {
    let lines = [];
    let currentText = '';
    let start_time = undefined;
    let end_time = undefined;
    let state = 'BEGIN';

    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (state === 'BEGIN') {
            currentText = word.text;
            start_time = parseFloat(word.start_time)
            end_time = parseFloat(word.end_time)
            state = 'PROGRESS';
        } else {
            currentText += ' ' + word.text;
            end_time = parseFloat(word.end_time)
        }

        if (word.text.endsWith('.') || word.text.endsWith('?') || word.text.endsWith('!') || currentText.length >= 45) {
            state = 'BEGIN';
            if (currentText.length < 15) {
                const lastLine = lines[lines.length - 1];
                lines[lines.length - 1] = {
                    ...lastLine,
                    text: lastLine.text + ' ' + currentText,
                    end_time: formatTimestamp(end_time),
                }
            } else {
                lines.push({
                    text: currentText,
                    start_time: formatTimestamp(start_time),
                    end_time: formatTimestamp(end_time),

                })
            }
        }
    }

    // console.log(lines)

    let result = "";
    let counter = 1;

    for (let line of lines) {
        result += `${counter}\n`;
        result += `${line.start_time} --> ${line.end_time}\n`;
        result += `${line.text}\n\n`;
        counter += 1;
    }

    // console.log(result);
    // console.log(btoa(result))
    return result;
}

export function createSubtitle(words) {
    let lines = [];
    let currentText = '';
    let start_time = undefined;
    let end_time = undefined;
    let state = 'BEGIN';

    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (state === 'BEGIN') {
            currentText = word.text;
            start_time = parseFloat(word.start_time)
            end_time = parseFloat(word.end_time)
            state = 'PROGRESS';
        } else {
            currentText += ' ' + word.text;
            end_time = parseFloat(word.end_time)
        }

        if (word.text.endsWith('.') || word.text.endsWith('?') || word.text.endsWith('!') || currentText.length >= 45) {
            state = 'BEGIN';
            if (currentText.length < 15) {
                const lastLine = lines[lines.length - 1];
                lines[lines.length - 1] = {
                    ...lastLine,
                    text: lastLine.text + ' ' + currentText,
                    end_time: formatTimestamp(end_time),
                }
            } else {
                lines.push({
                    text: currentText,
                    start_time: formatTimestamp(start_time),
                    end_time: formatTimestamp(end_time),

                })
            }
        }
    }

    // console.log(lines)

    let result = "WEBVTT\n\n";

    for (let line of lines) {
        result += `${line.start_time} --> ${line.end_time}\n`;
        result += `${line.text}\n\n`
    }

    // console.log(result);
    // console.log(btoa(result))
    return result;
}