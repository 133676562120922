export const FETCH_VIDEOS = '@video/FETCH_VIDEOS';
export const FETCH_VIDEO = '@video/FETCH_VIDEO';
export const CHANGE_LOADING = '@video/CHANGE_LOADING';
export const CHANGE_VIDEOS = '@video/CHANGE_VIDEOS';
export const CHANGE_VIDEO = '@video/CHANGE_VIDEO';
export const CHANGE_LAST_PREVIEW = '@video/CHANGE_LAST_PREVIEW';
export const SAVE_PREVIEW = '@video/SAVE_PREVIEW';
export const SAVE_PENDING = '@video/SAVE_PENDING';


export const fetchVideos = (payload) => ({
    type: FETCH_VIDEOS,
    payload: { ...payload }
})

export const changeLoading = (payload) => ({
    type: CHANGE_LOADING,
    payload
})

export const changeVideos = (videos) => ({
    type: CHANGE_VIDEOS,
    payload: [...videos]
})

export const changeVideoSelected = (video) => ({
    type: CHANGE_VIDEO,
    payload: { ...video }
})

export const changeLastPreview = (lastPreview) => ({
    type: CHANGE_LAST_PREVIEW,
    payload: lastPreview
})

export const fetchVideo = (video) => ({
    type: FETCH_VIDEO,
    payload: video
})

export const savePreview = (video) => ({
    type: SAVE_PREVIEW,
    payload: video
})

export const savePending = (payload) => ({
    type: SAVE_PENDING,
    payload
})

