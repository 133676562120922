import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchVideos, fetchVideo } from 'actions/video';
import { Redirect } from 'react-router-dom';

const HomePage = () => {
    const { videos, busy, videoSelected } = useSelector(state => state.video);
    const dispatch = useDispatch();

    useEffect(() => {
        //getVideos
        dispatch(fetchVideos({ page: 1, pageSize: 10 }));
    }, []);

    if (videoSelected.id) {
        return <Redirect to="/video-editor" />
    }

    return (
        <section>
            {busy ? <p>Loading...</p> :
                <ul>
                    {videos.map(video => (
                        <li
                            key={video.id}
                            onClick={() => dispatch(fetchVideo(video))}>{video.name}</li>
                    ))}
                </ul>
            }
        </section>
    )
};

export default HomePage;
