import * as Actions from 'actions/auth';

const INITIAL_STATE = {
    logged: false,
    busy: false,
}

const auth = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Actions.DO_LOGIN:
            // TODO handle action
            return {...state, busy: true};

        case Actions.LOGIN_SUCCESS:
            return {...state, busy: false, logged: true};

        case Actions.LOGIN_FAIL:
            return {...state, busy: false, logged: false};
        
        default:
            return state;
    }
}

export default auth;
