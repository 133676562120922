import axios from "axios";

let tokens = {
    access: null,
    refresh: null,
};

const webclient = axios.create({
    baseURL: 'https://8i43pkr257.execute-api.us-east-1.amazonaws.com/dev/',
    timeout: 20000,
    headers: {}
})

function _update_tokens(access, refresh) {
    tokens = {
        access,
        refresh: refresh,
    }

    webclient.defaults.headers['Authorization'] = 'Bearer ' + access;
}

async function _api_request(request) {
    try {
        return await webclient.request(request);
    } catch (error) {
        if (error.response && error.response.status === 401) {
            refresh_token();
            return await webclient.request(request);
        } else {
            throw (error);
        }
    }
}

async function _api_get(endpoint, params) {
    const request = {
        url: endpoint,
        method: 'get',
        params,
    };

    try {
        return await _api_request(request);
    } catch (error) {
        console.log('### exception', error)
    }
}

async function _api_post(endpoint, data) {
    const request = {
        url: endpoint,
        method: 'post',
        data,
    };

    try {
        return await _api_request(request);
    } catch (error) {
        console.log('### exception', error)
    }
}

export async function login(username, password) {
    const request = {
        url: 'auth/token',
        method: 'post',
        data: {
            'grant_type': 'password',
            username,
            password
        }
    }

    let resp = await webclient.request(request);

    _update_tokens(resp.data['access_token'], resp.data['refresh_token']);
}

export async function refresh_token() {
    const request = {
        url: 'auth/token',
        method: 'post',
        data: {
            grant_type: 'refresh_token',
            refresh_token: tokens.refresh,
        }
    }

    let resp = await webclient.request(request);
    _update_tokens(resp.data['access_token'], resp.data['refresh_token']);
}

export async function listVideos(page, pageSize) {
    const params = {
        page,
        pageSize
    }
    const resp = await _api_get('video/list', params);
    console.log(resp);
    return resp
}

export async function fetchVideo(video_id) {
    const resp = await _api_get(`video/${video_id}`);
    console.log(resp);
    return resp
}

export async function getReviewVideo(video_id) {
    const resp = await _api_get(`video/${video_id}/review/latest`);
    console.log(resp);
    return resp

}

export async function saveReviewVideo(video_id, review) {
    const resp = await _api_post(`video/${video_id}/review/save`, review);
    console.log(resp);
    return resp
}

