import React from 'react';
import ContentEditable from 'react-contenteditable';
import { diffArrays } from 'diff';
import { connect } from 'react-redux';

class TimedWord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            currentValue: undefined,
            test: false,
        }
    }

    clickHandler = e => {
        e.preventDefault();
        if (e.shiftKey) {
            this.setState({
                edit: true,
                currentValue: this.props.text,
            });
        } else if (e.ctrlKey) {
            this.setState({
                test: !this.state.test,
            })
            console.log("## test")
        } else if (this.props.callback) {
            this.props.callback(this.props.marker);
        }
    }

    changeHandler = e => {
        this.setState({
            currentValue: e.target.value
        });
    }

    keyDown = e => {
        if (e.key === "Enter") {
            e.preventDefault();
            this.setState({
                edit: false,
            })
            if (this.props.onChange) {
                this.props.onChange(this.props.itemId, this.state.currentValue);
            }
        } else if (e.key === "Escape") {
            e.preventDefault();
            this.setState({
                edit: false,
            })
        }
    }


    _renderInternal() {
        const { itemId, text } = this.props;

        if (this.state.edit) {
            return (
                <input autoFocus value={this.state.currentValue} onChange={this.changeHandler} onKeyDown={this.keyDown} />
            );
        } else {
            const style = this.props.selected ? { color: 'red' } : undefined;
            const name = this.props.selected ? 'selected' : '';
            return (<a className={name} href={itemId} onClick={this.clickHandler} style={style}>{text} </a>);
        }
    }

    render() {
        const component = this._renderInternal();
        return (
            <span>
                {component}
            </span>
        )
    }
}

class WordMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentEditor: undefined,
            currentValue: undefined,
            // updatedWords: {},
            selectedId1: undefined,
            editText: this.buildText(props.data),
            editMode: false,
            // currentItems: undefined,
        }
        this.contentEditable = React.createRef();
        this.throttleTimer = undefined;
        this.delayedText = undefined;
        this.currentItems = undefined;
        const worker = new Worker('worker.js')
        this.worker = worker;

        this.worker.onmessage = (msg) => {
            this.currentItems = msg.data.currentItems
            if (this.props.onUpdateWords) {
                this.props.onUpdateWords(this.currentItems)
            }
        }
    }

    _calculate_bin(currentTime, start_time, end_time) {
        if (!this.props.highlight) {
            return false;
        }
        const binSize = 300;
        start_time = parseInt(start_time * 100);
        // end_time = parseInt(end_time * 100);

        currentTime = parseInt(currentTime * 100);

        return (currentTime >= start_time && currentTime <= start_time + binSize)
    }

    _merge_items = (id1, id2) => {
        let index1 = -1, index2 = -1;

        console.log("### merge id", id1, id2);

        if (id1 === id2) {
            return;
        }

        const items = this.props.data;
        for (let i = 0; i < items.length; i++) {
            if (items[i].id === id1) {
                index1 = i;
            } else if (items[i].id === id2) {
                index2 = i;
            } else if (index1 !== -1 && index2 !== -1) {
                console.log("#### merge break")
                break;
            }
        }

        console.log("#### merge index", index1, index2);
        this.setState({ selectedId1: undefined });

        if (this.props.onMergeWords) {
            this.props.onMergeWords(index1, index2);
        }
    }

    buildMap(items, callback) {
        let words = [];

        const handler = (marker) => {
            callback(marker);
        }

        const change = (id, text) => {
            // this.setState({
            //     updatedWords: {...this.state.updatedWords, [id]: text}
            // })
            let index = 0;
            const items = this.props.data;
            for (index = 0; index < items.length; index++) {
                if (items[index].id === id) {
                    break;
                }
            }
            if (this.props.onEditWord) {
                this.props.onEditWord(index, text);
            }
            // console.log(this.state)
        }

        const handlerMerge = (id) => {
            if (this.state.selectedId1) {
                this._merge_items(this.state.selectedId1, id);
            } else {
                this.setState({
                    selectedId1: id,
                })
            }
        }

        for (const item of items) {
            let itemId = item.id;
            // const text = (this.state.updatedWords[itemId]) ? this.state.updatedWords[itemId] : item.text;
            const text = item.text;
            const start_time = parseFloat(item.start_time);
            const end_time = parseFloat(item.end_time);
            // const selected = (this.props.currentTime >= start_time) && (this.props.currentTime <= end_time) ? true : false;

            if (this.props.mergeMode) {
                const selected = itemId === this.state.selectedId1 ? true : false;
                words.push(
                    <TimedWord key={itemId}
                        itemId={itemId}
                        selected={selected}
                        text={text}
                        marker={item.start_time}
                        callback={() => handlerMerge(itemId)}
                        onChange={change} />
                );
            } else {
                const selected = this._calculate_bin(this.props.currentTime, start_time, end_time)
                words.push(
                    <TimedWord key={itemId} itemId={itemId} selected={selected} text={text} marker={item.start_time} callback={handler} onChange={change} />
                );
            }
        }

        return (
            <div>{words}</div>
        );
    }

    buildText(items) {
        let words = [];
        for (const item of items) {
            words.push(item.text);
        }

        return words.join(' ');
    }

    handleEditChange = (evt) => {
        const text = evt.target.value;
        const rate = 100; // ms
        if (this.throttleTimer) {
            this.delayedText = text;
        } else {
            this.updateWords(text);
            this.delayedText = undefined;
            this.throttleTimer = setTimeout(() => {
                this.updateWords(this.delayedText);
                this.delayedText = undefined;
                this.throttleTimer = undefined;
            },
                rate);
        }
        this.setState({ editText: evt.target.value })
    }

    checkEndSentence(text) {
        return (text.endsWith('.') || text.endsWith('?') || text.endsWith('!'));
    }

    updateWords = (newText) => {
        if (newText === undefined) {
            return
        }
        let tmpDiv = document.createElement('div');
        tmpDiv.innerHTML = newText;
        const editText = tmpDiv.innerText;

        const items = this.state.currentItems ? this.state.currentItems : this.props.data;
        this.worker.postMessage({ items, editText })
    }

    handleDiffChange = (items, oldText, newText) => {
        // find offset
        let offset;
        for (let i = 0; i < items.length; i++) {
            // console.log("=====", items[i], oldText[0])
            if (items[i].id === oldText[0].id) {
                offset = i;
                break;
            }
        }
        if (oldText.length === newText.length) {
            for (let i = 0; i < oldText.length; i++) {
                items[offset + i].text = newText[i].text;
            }
        } else if (oldText.length < newText.length) {
            // TODO handle punctuation
            let i;
            for (i = 0; i < oldText.length - 1; i++) {
                items[offset + i].text = newText[i].text;//
            }
            items[offset + i].text = newText.slice(i).map(x => x.text).join(' ');
        } else {
            console.log("######## case 3", items)
            let delta = oldText.length - newText.length;


            // TODO handle punctuation
            let i;
            for (i = 0; i < newText.length; i++) {
                // if (this.checkEndSentence(newText[i].text)) {
                // }
                items[offset + i].text = newText[i].text;
            }
            let j = i;
            while (delta > 0) {
                items = this.mergeWords(items, offset + j, offset + j + 1)
                j += 1;
                delta -= 1;
            }
            items[offset + i].text = newText.slice(i).map(x => x.text).join(' ');
        }

        return items;
    }

    mergeWords = (words, index1, index2) => {
        let x = undefined;
        if (index2 !== index1 + 1) {
            console.log("### mergeWords invalid indexes", index1, index2);
        } else {
            console.log("### mergeWords ", index1, index2, words);
            x = [...words];
            let newItem = {
                id: x[index1].id,
                start_time: x[index1].start_time,
                end_time: x[index2].end_time,
                text: x[index1].text + ' ' + x[index2].text
            }

            x[index1] = newItem;
            x.splice(index2, 1);
        }

        return x;
    }



    handleSetEditMode = () => {
        if (this.state.editMode) {
            let tmpDiv = document.createElement('div');
            tmpDiv.innerHTML = this.state.editText;
            const editText = tmpDiv.innerText;

            console.log("#### saving")
            // console.log("#### edited", this.state.editText)
            // console.log("#### original", this.buildText(this.props.data))
            const items = this.state.currentItems ? this.state.currentItems : this.props.data;
            let currentItems = [...items];
            let timedTokens = this.tokenizeTimedWords(currentItems)
            let textTokens = this.tokenizeText(editText);
            // console.log(timedTokens)
            // console.log(textTokens)

            const compare = (left, right) => left.text === right.text;

            let diff = diffArrays(timedTokens, textTokens, { comparator: compare })
            console.log("### diff", diff)

            for (let i = 0; i < diff.length; i++) {
                if (diff[i].removed) {
                    if (i + 1 < diff.length) {
                        const next = diff[i + 1];
                        if (next.added) {
                            console.log("#### change", diff[i].value, next.value);
                            currentItems = this.handleDiffChange(currentItems, diff[i].value, next.value);
                            i += 1;
                        } else {
                            console.log("#### removed", diff[i].value)
                        }
                    }
                } else if (diff[i].added) {
                    console.log("#### added", diff[i].value)
                }
            }
            // this.setState({currentItems})
            if (this.props.onUpdateWords) {
                this.props.onUpdateWords(currentItems);
            }

            // console.log("### end diff", result)
        } else {
            this.setState({
                editText: this.buildText(this.props.data),
            })
        }
        this.setState({
            editMode: !this.state.editMode,
        })
    }

    tokenizeTimedWords(items) {
        let tokens = [];
        for (const item of items) {
            tokens.push({
                id: item.id,
                text: item.text
            })
        }

        return tokens;
    }

    tokenizeText(text) {
        let words = text.split(/\s+/);
        let tokens = [];
        for (const word of words) {
            tokens.push({
                text: word
            })
        }

        return tokens;
    }


    render() {
        const { callback, isPending } = this.props;
        const items = this.state.currentItems ? this.state.currentItems : this.props.data;

        const wordmap = this.buildMap(items, callback);

        let child;
        if (this.state.editMode) {
            child = <ContentEditable
                innerRef={this.contentEditable}
                html={this.state.editText}
                onChange={this.handleEditChange}
                style={{ width: '48vw', lineHeight: '1.2em', textAlign: 'justify', padding: 5 }}
            />
        } else {
            child = <div style={{ width: '48vw', lineHeight: '1.2em', textAlign: 'justify', padding: 5 }} >{wordmap}</div>
        }

        return (
            <div style={{ width: '50vw', lineHeight: '1.2em', overflowY: 'auto', textAlign: 'justify', padding: 5 }}>
                <div>
                    <button onClick={this.handleSetEditMode}>
                        {this.state.editMode ? 'Ir para modo revisão' : 'Ir para modo edição'}
                    </button>
                    <span style={{ paddingLeft: 20 }} >{isPending ? 'Revisão não salva' : 'Revisão salva'}</span>
                </div>
                {child}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({ isPending: state.video.savePending })
export default connect(mapStateToProps)(WordMap);;